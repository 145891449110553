<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级课程参数</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="行政区划" class="searchboxItem">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                v-model="areaId"
                :options="areatreeList"
                clearable
                :props="propsarea"
                size="small"
              ></el-cascader>
            </div>
            <span title="培训类型" class="searchboxItem ci-full flexcc">
              <span
                class="itemLabel treelabel"
                style="width: 95px; font-size: 14px; padding: 0 0.5rem"
                >培训类型:</span
              >
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </span>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="行政区划"
                align="center"
                show-overflow-tooltip
                prop="areaNamePath"
              />
              <el-table-column
                label="培训类型"
                align="center"
                show-overflow-tooltip
                prop="trainTypeNamePath"
              />
              <!-- <el-table-column
                label="课件是否人脸签到"
                align="center"
                show-overflow-tooltip
                prop="paraIsLogin"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.paraIsLogin == true ? "是" : "否" }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="课件未完成是否每次人脸签到"
                align="center"
                show-overflow-tooltip
                prop="paraIsLoginEvery"
                width="115px"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.paraIsLoginEvery == true ? "是" : "否"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="课后习题是否签到"
                align="center"
                show-overflow-tooltip
                prop="paraIsMiddle"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.paraIsMiddle == true ? "是" : "否"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="习题未通过是否每次签到"
                align="center"
                show-overflow-tooltip
                prop="paraIsMiddleEvery"
                width="105px"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.paraIsMiddleEvery == true ? "是" : "否"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="单元测试是否签到"
                align="center"
                show-overflow-tooltip
                prop="unitTestFace"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.unitTestFace == true ? "是" : "否"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="课程考试是否签到"
                align="center"
                show-overflow-tooltip
                prop="paraIsAfter"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.paraIsAfter == true ? "是" : "否" }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="考试未通过是否每次签到"
                align="center"
                show-overflow-tooltip
                prop="paraIsAfterEvery"
                width="115px"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.paraIsAfterEvery == true ? "是" : "否"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="人脸识别防挂课时长"
                align="center"
                show-overflow-tooltip
                prop="paraIsSnapshots"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.paraIsSnapshots == true
                      ? scope.row.paraIsRandom == true
                        ? "随机时间"
                        : "指定时间"
                      : "--"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="随机最小时间(分钟)"
                align="center"
                show-overflow-tooltip
                prop="paraRandomTimeMin"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.paraIsRandom == true
                      ? scope.row.paraRandomTimeMin
                      : "--"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="随机次数"
                align="center"
                show-overflow-tooltip
                prop="paraRandomNum"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.paraIsRandom == true
                      ? scope.row.paraRandomNum
                      : "--"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="指定时长（分钟）"
                align="center"
                show-overflow-tooltip
                prop="paraTime"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.paraIsRandom == true ? "--" : scope.row.paraTime
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="最大次数"
                align="center"
                show-overflow-tooltip
                prop="paraTimeNum"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.paraTimeNum == 0 ? "--" : scope.row.paraTimeNum
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="强制课后练习"
                align="center"
                show-overflow-tooltip
                prop="paraIsPaper"
                width="115px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.paraIsPaper == true ? "是" : "否" }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="课后习题显示答题解析"
                align="center"
                show-overflow-tooltip
                prop="paraIsAnalysis"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.paraIsAnalysis == true ? "是" : "否"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="单元测试显示答题解析"
                align="center"
                show-overflow-tooltip
                prop="unitTestAnalysis"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.unitTestAnalysis == true ? "是" : "否"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="课后考试显示答题解析"
                align="center"
                show-overflow-tooltip
                prop="paraIsExamAnalysis"
              >
                <template slot-scope="scope">{{
                  scope.row.paraIsExamAnalysis == true ? "是" : "否"
                }}</template>
              </el-table-column>
              <el-table-column
                label="是否强制评价"
                align="center"
                show-overflow-tooltip
                prop="paraIsEvaluate"
                width="115px"
              >
                <template slot-scope="scope">{{
                  scope.row.paraIsEvaluate == true ? "是" : "否"
                }}</template>
              </el-table-column>
              <el-table-column
                label="按顺序学习"
                align="center"
                show-overflow-tooltip
                prop="paraOrderStudy"
              >
                <template slot-scope="scope">{{
                  scope.row.paraOrderStudy == true ? "是" : "否"
                }}</template>
              </el-table-column>
              <el-table-column
                label="每日学习时长限制"
                align="center"
                show-overflow-tooltip
                prop="paraTimeLimit"
              >
                <template slot-scope="scope">{{
                  scope.row.paraTimeLimit == "[{}]" ? "否" : "是"
                }}</template>
              </el-table-column>
              <el-table-column
                label="人群类型"
                align="center"
                show-overflow-tooltip
                prop="startDate"
                width="200"
              >
                <template slot-scope="scope">
                  <span
                    v-for="(item, index) in JSON.parse(scope.row.paraTimeLimit)"
                    :key="index"
                    >{{
                      item.personType
                        ? $setDictionary("CROWDTYPE", item.personType) +
                          "：每日学习" +
                          item.timeLimit +
                          "课时；"
                        : "无"
                    }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="是否点名认证"
                align="center"
                show-overflow-tooltip
                prop="paraIsRoll"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.paraIsRoll">是</span>
                  <span v-else>否</span>
                </template>
              </el-table-column>

              <el-table-column
                label="点名读秒数(秒)"
                align="center"
                show-overflow-tooltip
                prop="paraRollSecond"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.paraRollSecond }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="机构允许失败次数"
                align="center"
                show-overflow-tooltip
                prop="paraRollOrgFailureNum"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.paraRollOrgFailureNum }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="监管允许失败次数"
                align="center"
                show-overflow-tooltip
                prop="paraRollGovFailureNum"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.paraRollGovFailureNum }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="特殊完成条件"
                align="center"
                show-overflow-tooltip
                prop="paraCompleteCondition"
                width="80px"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.paraCompleteCondition
                      ? "完成总时长" + scope.row.paraCompleteCondition + "%以上"
                      : "无"
                  }}
                </template>
              </el-table-column> -->
              <el-table-column
                label="操作"
                align="center"
                width="120px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    @click="toSeeInfo(scope.row.paraId)"
                    >详请</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const className = "eduCertService";
import QRCode from "qrcodejs2"; // 引入qrcode
import Empty from "@/components/Empty.vue";
import moment from "moment";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "SupervisionEnd/classSubjectParam",
  components: {
    Empty,
    tree,
  },
  data() {
    return {
      tableHeader: {
        background: "#5C6BE8",
        color: "#fff",
        borderColor: "#5C6BE8",
      },
      areatreeList: [], // 行政区划
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      ruleForm: {
        Trainingtype: "",
      },
      tableData: [],
      params: {},
      areaId: "",
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted() {
    this.getTableHeight();
    this.getareatree();
  },
  methods: {
    /* tree */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    getData() {
      const params = {
        // projectName: this.projectName || "",
      };
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.$post("/gov/project/course/param/page-list", params).then((ret) => {
        this.tableData = ret.data.list;
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    // 详情
    toSeeInfo(paraId) {
      this.$router.push({
        path: "/web/operate/seeClassCurriculumParameterInfo",
        query: {
          paraId,
          stu: "edit"
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 400px;
    height: 400px;
    background-color: #999; //设置白色背景色
  }
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>